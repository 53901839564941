// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-cancelling-requests-mdx": () => import("/opt/build/repo/docs/src/pages/CancellingRequests.mdx" /* webpackChunkName: "component---src-pages-cancelling-requests-mdx" */),
  "component---src-pages-server-side-rendering-mdx": () => import("/opt/build/repo/docs/src/pages/ServerSideRendering.mdx" /* webpackChunkName: "component---src-pages-server-side-rendering-mdx" */),
  "component---src-pages-refetching-mdx": () => import("/opt/build/repo/docs/src/pages/Refetching.mdx" /* webpackChunkName: "component---src-pages-refetching-mdx" */),
  "component---src-pages-getting-started-mdx": () => import("/opt/build/repo/docs/src/pages/GettingStarted.mdx" /* webpackChunkName: "component---src-pages-getting-started-mdx" */),
  "component---src-pages-type-script-mdx": () => import("/opt/build/repo/docs/src/pages/TypeScript.mdx" /* webpackChunkName: "component---src-pages-type-script-mdx" */),
  "component---src-pages-use-axios-mdx": () => import("/opt/build/repo/docs/src/pages/useAxios.mdx" /* webpackChunkName: "component---src-pages-use-axios-mdx" */),
  "component---src-pages-custom-axios-instance-mdx": () => import("/opt/build/repo/docs/src/pages/CustomAxiosInstance.mdx" /* webpackChunkName: "component---src-pages-custom-axios-instance-mdx" */),
  "component---src-pages-use-lazy-axios-mdx": () => import("/opt/build/repo/docs/src/pages/useLazyAxios.mdx" /* webpackChunkName: "component---src-pages-use-lazy-axios-mdx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/docs/.docz/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

